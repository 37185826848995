.walletConnectBtn {
    margin: 1px !important;
}

.font600 {
    font-weight: 600 !important;
}

.connected {
    color: #059669 !important;
    font-weight: 600;
    font-size: 18px !important;
}

.userAddress {
    font-weight: 550 !important;
    font-size: 1rem !important;
}

.userBtn {
    display: flex;
    flex-direction: row;
    font-weight: 550;
    color: #6b7280 !important;
    align-items: center;
}

.userBtnCopy {
    margin-right: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.userBtnBsc {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.balance {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
    margin: 5px 0px;
    font-size: 18px;
}

.balanceValue {
    font-weight: 500;
    margin-left: 5px;
    color: gray;
}

.fgcModalBtn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
}

.width100 {
    width: 100%;
}

.bnbInput {
    height: 42px;
    border-radius: 4px;
    color: gray;
    width: 100%;
}

.calculateFTCpaddingBot {
    font-weight: 600;
    font-size: 13px;
    color: green;
    margin: 5px 0px;
    padding-bottom: 65px;
}


.calculateFTC {
    font-weight: 600;
    font-size: 13px;
    color: green;
    margin: 5px 0px;
}

.addressBtn {
    font-size: 20px !important;
    font-weight: 600 !important;
}

.buyBtn {
    background: var(--gradient-text) !important;
    font-size: 20px !important;
    font-weight: 600 !important;
}

.buyBtn:hover {
    background: var(--gradient-textInverse) !important;
}

.kycModal {
    background-color: #1e122d;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: absolute;
    right: 30px;
    padding: 15px;
    border-radius: 15px;
    box-shadow: -.2em .2em .8em #000;
    max-width: 300px;
    text-align: center;
    margin-top: 5px;
}

.w-10 {
    width: 2rem;
}

.h-10 {
    height: 2rem;
}

.kycBoxElem {
    margin: 3px;
    font-weight: 600;
}

.saft-url {
    color: #5b43b8;
    font-weight: bold;
}

@media screen and (max-width: 1050px) {
    .kycModal {
        position: relative;
        margin: 0 auto;
        left: 0;
        right: 0;
        margin-top: 20px;
    }
}
.parentBalanceBNB {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 41px;
    position: relative;
}


.parentBuy {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 35px;
}

.childBuy {
    width: 175px;
}

.BNBprice {
    color: #f2bb30;
    width: 100%;
    right: 0;
    position: absolute;
}
.FTCprice {
    color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    right: 0;
    position: absolute;
}
.FTCpriceletter {
    color: black;
}

.parentCoin {
    display: flex;
    flex-direction: column;
    margin: 5px 0px;
}

.divRow {
    display: flex;
    flex-direction: row;
}

.inputSaft {
    margin-right: 3px;
}

.parentSAFT {
    display: flex;
    flex-direction: row;
}

.buyFTCabs {
    position: absolute;
    bottom: 20px;
    right: 25px;
    padding-right: 1rem;
}

.parentCheckSaft {
    position: absolute;
    bottom: 20px;
    left: 25px;
    color: gray;
    padding-left: 1rem;
}

.closeBtn {
    padding-right: 1rem;
}