.fgc__header {
    display: flex;
    flex-direction: row;
}

.fgc__header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    color: white;
    margin-right: 5rem;
}

.fgc__header-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 50px;
    letter-spacing: -0.04em;
}

.fgc__header-content p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-text);

    margin-top: 1.5rem;
}

.fgc__header-content__input {
    width: 100%;
    margin: 2rem 0 1rem;

    display: flex;
    flex-direction: row;
}

.fgc__header-content__input input {
    flex: 2;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: var(--color-footer);
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: #fff;

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.fgc__header-content__input button {
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: #FF4820;
    border: 2px solid #FF4820;
    padding: 0 1rem;
    color: #fff;
    cursor: pointer;
    outline: none;

    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.fgc__header-content__people {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    margin-top: 2rem;
}

.fgc__header-content__people img {
    width: 181.79px;
    height: 38px;
}

.fgc__header-content__people p {
    margin: 0 0 0 1rem;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 38px;
    color: #fff;
    text-align: center;
}

.fgc__header-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: end;
}

.fgc__header-image img {
    width: 100%;
}

.h_button {
    padding: 0.7rem 3.5rem;
    color: #fff;
    background: linear-gradient(103.22deg, #638092 -13.86%, #2d2d38 105.55%);
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    margin-right: 15px;
}

.h_button:hover {
    opacity: 0.7;
}

.socialContainer {
    padding-top: 2rem;
    justify-content: center;
}

.social {
    max-width: 8em!important;
    display: inline;
}

.social a {
    display: inline-block;
    width: 47px;
    height: 47px;
    position: relative;
    overflow: hidden;
    vertical-align: middle;
    margin-right: 8px;
}

.social a:hover {
    transform: scale(1.1) translateZ(0px);
}

.social a img {
    width: 100%;
}

@media screen and (min-width: 1024px) {
    .socialContainer {
        justify-content: flex-start;
    }
}
@media screen and (max-width: 1050px) {
    .fgc__header {
        flex-direction: column;
    }

    .fgc__header-content {
        margin: 0 0 3rem;
    }

    .fgc__header-image {
        display: none;
    }

    .h_button {
        margin-bottom: 20px;
    }

    .socialContainer {
        margin: 0 auto;
    }
}

@media screen and (max-width: 650px) {
    .fgc__header-content h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .fgc__header-content p {
        font-size: 16px;
        line-height: 24px;
    }
    
    .fgc__header-content__people {
        flex-direction: column;
    }

    .fgc__header-content__people p {
        margin: 0;
    }

    .fgc__header-content__input input,
    .fgc__header-content__input button {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 490px) {
    .fgc__header-content h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .fgc__header-content p {
        font-size: 14px;
        line-height: 24px;
    }

    .fgc__header-content__input input,
    .fgc__header-content__input button {
        font-size: 12px;
        line-height: 16px;
    }
}