.fgc__brand {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.fgc__brand a {
    flex: 1;
    max-width: 150px;
    min-width: 120px;
    margin: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;
}

.mediaImg {
    cursor: pointer;
} 

.mediaImg:hover {
    transform: scale(1.05);
}
