* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: var(--color-bg);
}

a {
  color: unset;
  text-decoration: none;
}

.gradient__bg {
  background: #1d1d20;
}

.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section__padding {
  padding: 0 0 0 6rem;
}

.section__padding2 {
  padding: 4rem 6rem;
}

.section__margin {
  margin: 4rem 6rem;
}

.scale-up-center {
  -webkit-animation: scale-up-center 1.75s both linear;
  animation: scale-up-center 1.75s both linear;

  /* animation-duration: 1.5s;     */
  /* -webkit-animation-duration: 1.5s; */

  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}

.notification-container {
  top: unset!important;
  bottom: 10px;
}
.notification-closer:after {
  left: 1px;
  top: 1px;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.85);
    transform: scale(0.85);
  }
  25% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
  50% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  75% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(0.85);
    transform: scale(0.85);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.85);
    transform: scale(0.85);
  }
  25% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
  50% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  75% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(0.85);
    transform: scale(0.85);
  }
}

@media screen and (max-width: 700px) {
  .section__padding {
    padding: 4rem;
  }

  .section__margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  }

  .section__margin {
    margin: 4rem 2rem;
  }
}