.square {
  width: 100px;
  height: 100px;
  background: #fff;
  margin-top: 53rem;
}

.fgc__roadmap-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
    color: white;
    max-width: 510px;
}

.subtitle3 {
    text-align: center;
    color: white;
}

.fgc__roadmap h4 {
    font-weight: bold;
}

.bg-primary-gradientTo {
    --tw-bg-opacity: 1;
    background-color: rgba(232,63,255,var(--tw-bg-opacity));
}

.border-primary-gradientFrom {
    --tw-border-opacity: 1;
    border-color: rgba(131,48,255,var(--tw-border-opacity));
}

.roadmapDate {
  color: #8931fe;
  font-weight: bold;
}

.roadmapPoint {
  border-color: #8931fe;
}

.roadmapLine::before {
  background-color: #8931fe !important;
  content: '';
  position: absolute;
}

.before\:w-1:before {
    content: "";
    width: .25rem;
}
.before\:h-full:before {
    content: "";
    height: 100%;
}
.before\:left-2:before {
    content: "";
    left: .5rem;
}
.before\:top-0:before {
    content: "";
    top: 0;
}
.before\:absolute:before {
    content: "";
    position: absolute;
}
.even\:flex-row-reverse:nth-child(2n) {
    flex-direction: row-reverse;
}
@media (min-width: 1024px) {
    .lg\:before\:left-1\/2:before {
        content: "";
        left: calc(50% + 7.5px);
    }
}